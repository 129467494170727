import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-visibility"
export default class extends Controller {
  static targets = [ "content" ]
  connect() {
  }

  toggle() {
    this.contentTargets.forEach((element) => { element.classList.toggle("hidden") });
  }
}
