import { Controller } from "@hotwired/stimulus"

// Connects to data-controller=""enable-disable-form-button""
export default class extends Controller {
  static targets = [ "formButton", "formIssue", "formDetails" ]
  connect() {
  }

  enableDisableButton() {
    
    if (this.formIssueTargets[0].value && this.formDetailsTargets[0].value) {
      this.formButtonTargets.forEach((element) => { element.disabled = false; });
    }
    else {
      this.formButtonTargets.forEach((element) => { element.disabled = true; });
    }
  }
}
