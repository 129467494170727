<script>

export default {
  props: {
    rails_env: {
      type: String, // Define the type of the prop
      required: true, // Makes the prop mandatory
    },
  },
};

</script>

<template>
  <div class="">
    <div class="float-left shadow-sm bg-teal-200 p-2 ">{{ rails_env }}</div>
     <div class="float-right p-2 bg-slate-500">
      <div class="md:hidden">SM</div>
      <div class="hidden md:block lg:hidden">MD</div>
      <div class="hidden lg:block xl:hidden">LG</div>
      <div class="hidden xl:block 2xl:hidden">XL</div>
      <div class="hidden 2xl:block">2XL</div>
    </div>
  </div>
</template>