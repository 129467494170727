import { Controller }  from "@hotwired/stimulus"
import html2canvas from "html2canvas";

// Connects to data-controller="screenshot"
export default class extends Controller {
  static targets = [ "content" ]

  capture(event) {
    let tooltips = this.contentTarget.querySelectorAll(".tooltip");
    let elucid_watermark = this.contentTarget.querySelectorAll(".elucid-watermark");
    tooltips.forEach((selected) => selected.style.visibility = "hidden");
    elucid_watermark.forEach((selected) => selected.style.display = "block");
    html2canvas(this.contentTarget, { scale: 10 }).then(function (canvas) {
      let screenshot = canvas.toDataURL("image/png");
      let link = document.createElement('a');
      link.href = screenshot;
      let chart_title = event.params.title;
      link.download = chart_title.concat('.png');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    elucid_watermark.forEach((selected) => selected.style.display = "none");
    tooltips.forEach((selected) => selected.style.visibility = "visible");
  }
}