import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flyout-menu"
export default class extends Controller {
  static targets = [ "list" ]
  connect() {}

  toggle() {
    this.listTarget.classList.toggle("hidden");
  }
}
