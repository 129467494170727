import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';

export default class extends Controller {
  initialize() {
    this.choices = new Choices(this.element,
      {
        removeItems: true,
        removeItemButton: true,
        removeItemButtonAlignLeft: false,
        itemSelectText: ' ',
        noChoicesText: ' - '
      });
  }

  connect() { }
}