// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "../turbo-mount"



// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import { StreamActions } from "@hotwired/turbo";
import "../controllers"
import "trix"
import "@rails/actiontext"
import * as echarts from 'echarts'

document.addEventListener("trix-initialize", function(event) {
  const editor = event.target.editor;
  const toolbarElement = event.target.toolbarElement;

  // remove unwanted buttons
  toolbarElement.querySelector("[data-trix-button-group=block-tools]").remove();
  toolbarElement.querySelector("[data-trix-button-group=file-tools]").remove();
  // toolbarElement.querySelector("[data-trix-button-group=text-tools]").remove();
});

document.addEventListener("trix-file-accept", (e) => {
  e.preventDefault()
})

Turbo.StreamActions.remove_later = function() {
  setTimeout(() => {
    this.targetElements.forEach((element) => element.remove())
  }, this.getAttribute("delay"))
}


window.echarts = echarts;