import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-hidden-attribute"
export default class extends Controller {
  static targets = [ "content" ]
  connect() {
  }

  remove() {
    this.contentTargets.forEach((element) => { element.removeAttribute("hidden"); });
  }
}
