import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu-toggle"
export default class extends Controller {
  connect() {
  }

  toggle() {
    const event = new CustomEvent('toggle-mobile-menu')
    document.dispatchEvent(event)
  }
}
