import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="off-canvas-menu"
export default class extends Controller {
  static targets = [ "overlay", "menu", "close" ]
  connect() {}

  toggle() {
    this.overlayTarget.classList.toggle("hidden")
    this.overlayTarget.classList.toggle("opacity-100")
    this.overlayTarget.classList.toggle("opacity-0")
    this.menuTarget.classList.toggle("-translate-x-full")
    this.menuTarget.classList.toggle("translate-x-0")
    this.closeTarget.classList.toggle("opacity-0")
    this.closeTarget.classList.toggle("opacity-100")
  }
}
