import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="combobox"
export default class extends Controller {
  static targets = [ "list", "input", "searchForm"]
  connect() {}

  showList() {
    this.listTarget.classList.remove("hidden");
  }

  clear() {
    this.inputTarget.value = "";
    this.listTarget.classList.add("hidden");
  }

  toggle() {
    if (this.listTarget.classList.contains("hidden")) {
      this.showList();
    } else {
      this.clear();
    }
  }

  search(event) {
    if (event.target.value.length < 2) {
      this.listTarget.classList.add("hidden");
      return;
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchFormTarget.requestSubmit();
    }, 50);
  }
}
